import React from 'react';
import './App.css';
import BookmarkList from "./components/bookmark-list.component";

function App() {
  return (
    <BookmarkList />
  );
}

export default App;
