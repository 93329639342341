import {Component} from "react";
import Bookmark from "../types/bookmark.type";
import BookmarkService from "../service/bookmark.service";

type Props = {};
type State = {
    bookmarks: Array<Bookmark>
};

export default class BookmarkList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            bookmarks: []
        };
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        BookmarkService.getAll()
            .then((response: any) => {
                this.setState({
                    bookmarks: response.data
                });
                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    render() {
        const {bookmarks} = this.state;
        return (
            <ul>
                {bookmarks.map(bookmark => (
                    <li>
                        <a href={bookmark.location} target="_blank">{bookmark.title}</a>
                    </li>
                ))}
            </ul>
        );
    }
}