import api from "../api";
import Bookmark from "../types/bookmark.type"

class BookmarkService {
    getAll() {
        return api.get<Array<Bookmark>>("/bookmark/latest");
    }

    search(query: string) {
        return api.get<Array<Bookmark>>(`/bookmark?search=${query}`);
    }
}
export default new BookmarkService();
